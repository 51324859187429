// RESET CSS

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// WEBSITE STYLING

:root {
  --beige: #f2e8dc;
  --green: #1f6565;
  --darkred: #763839;
  --lightpink: #ca9e91;
  --mustard: #be7b35;
  --white: #ffffff;
}


/*special font*/
@font-face {
  font-family: "friendly_schoolmatesregular";
  src: url("./assets/fonts/friendly_schoolmates_regular_font_by_situjuh_7ntypes-webfont.woff")
      format("woff"),
    url("./assets/fonts/friendly_schoolmates_regular_font_by_situjuh_7ntypes-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}


.app {
  background-color: var(--beige);
  color: var(--darkred);
}

.container {
  padding: 50px 0 0 0;
  margin-bottom: 80px;

  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80vw;
}

.btn {
  margin: 15px auto;
  cursor: pointer;

  &--send {
    background: url("./assets/images/buttons/contact-send.svg") no-repeat;
    border: none;
    color: var(--white);

    &:hover {
      transform: scale(1.1);
      transition: all 200ms ease-in-out;
    }
  }

  &--back {
    position: relative;
    z-index: 10;
    background: url("./assets/images/buttons/contact-back.svg") no-repeat;
    border: none;
    color: var(--white);

    &:hover {
      transform: scale(1.1);
      transition: all 200ms ease-in-out;
    }
  }

  &--handwritten {
    font-family: "friendly_schoolmatesregular", cursive;
  }

  &--large {
    padding: 10px 30px;
    font-size: 1.2rem;
  }

  &--medium {
    padding: 8px 25px;
    font-size: 1.1rem;
  }

  &--small {
    padding: 6px 20px;
    font-size: 1rem;
  }
}

.btn--back-to-menu {
  margin: 20px;
}

