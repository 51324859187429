.bc1 {
  position: relative;
  left: -70px;
  bottom: -600px;
}

.bc2 {
  position: relative;
  left: -190px;
  bottom: -600px;
  width: 300px;
}

.bc3 {
  position: relative;
  right: -800px;
  bottom: -600px;
}

@media screen and (max-width: 1200px) {
  .bc2 {
    bottom: -650px;
  }
  .bc3 {
    right: -300px;
  }
}

@media screen and (max-width: 1024px) {
  .bc3 {
    right: -200px;
  }
}

@media screen and (max-width: 960px) {
  .bc1 {
    left: -100px;
    bottom: -650px;
  }

  .bc2 {
    display: none;
  }

  .bc3 {
    bottom: -450px;
    right: -600px;
  }
}

@media screen and (max-width: 768px) {
  .bc3 {
    bottom: -650px;
    right: -300px;
  }
}

@media screen and (max-width: 600px) {
  .bc1 {
    bottom: -700px;
  }

  .bc3 {
    bottom: -700px;
    right: -150px;
  }
}

@media screen and (max-width: 480px) {
  .bc1 {
    bottom: -800px;
    left: -200px;
  }

  .bc3 {
    bottom: -570px;
    right: -250px;
  }
}

@media screen and (max-width: 320px) {
  .bc1 {
    bottom: -850px;
  }

  .bc2 {
    display: none;
  }

  .bc3 {
    bottom: -650px;
    right: -120px;
  }
}
