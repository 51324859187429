.languages {
  &__container {
    width: 100%;
    padding-bottom: 80px;
  }

  &__tag {
    padding: 6px 12px;
    margin: 0 0 12px 12px;
    background-color: var(--lightpink);
    font-weight: 500;
    border-radius: 25px;
    display: inline-block;
    &__detail {
      font-weight: 300;
    }
  }
}
