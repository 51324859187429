.legal {
  &__page{
    min-height: 80vh;
    padding-top: 100px;
  }

  &__section {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin: 15px auto;
  }

  &__item {
    width: 49%;
  }

  &__title {
    margin: 0;
    text-align: right;
    padding-right: 15px;
  }

  &__text {
    margin: 0;
    text-align: left;
    padding-left: 15px;
  }

  &__bullet {
    margin: 15px 0;
    line-height: 1.5rem;
  }

  &__article {
    width: 70%;
    margin: 50px auto;
    text-align: left;
  }

  &__notice {
    margin-top: 20px;
  }

}

.--border-left {
  border-left: solid 1px black;
}
