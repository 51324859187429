.home {
  color: var(--darkred);

  &__name {
    font-size: 2rem;
  }

  &__profile-picture {
    margin: 3rem;
    height: 200px;
  }

  &__designation {
    &__text {
      margin: 1rem;
      font-size: 2.5rem;
    }
  }

  &__scroll-icon {
    margin: 1rem;
    font-size: 2rem;
    color: var(--lightpink);
// from https://codepen.io/whusterj/pres/bdYKop
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
  }
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}

.--small {
  font-size: 2rem;
}

.--smaller {
  font-size: 1.5rem;
}


@media screen and (max-width: 800px) {
  .home{
  
    &__name {
      font-size: 1.8rem;
    }
  
    &__designation {
      &__text {
        margin: 1rem;
        font-size: 2.2rem;
      }
    }
  }
  
  .--small {
    font-size: 1.8rem;
  }
  
  .--smaller {
    font-size: 1.3rem;
  }
  }



@media screen and (max-width: 480px) {
.home{

  &__profile-picture {
    margin: 1.5rem;
    height: 150px;
  }

  &__name {
    font-size: 1.7rem;
  }

  &__designation {
    &__text {
      margin: 1rem;
      font-size: 2rem;
    }
  }
}

.--small {
  font-size: 1.5rem;
}

.--smaller {
  font-size: 1rem;
}
}
