.skills {
  &__container {
    width: 400px;
  }

  &__tag {
    padding: 6px 12px;
    margin: 0 0 12px 12px;
    background-color: var(--lightpink);
    border-radius: 25px;
    display: inline-block;
  }

  &__tag--important {
    padding: 6px 12px;
    margin: 0 0 12px 12px;
    font-weight: 300;
    color: var(--beige);
    background-color: var(--darkred);
    border-radius: 25px;
    display: inline-block;
  }
}

@media screen and (max-width: 960px) {
  .skills {
    &__container {
      width: 100%;
    }
  }
}
