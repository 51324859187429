
// fullpage 
#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background: var(--darkred);
}

#fp-nav ul li .fp-tooltip{
  color:var(--lightpink); 
  font-family: "Jost", sans-serif;
}
