:root {
  --coeff1: 5%;
  --coeff2: 8%;
  --coeff3: 10%;
  --coeff4: 15%;
  --coeff5: 20%;
  --coeff6: 25%;
  --coeff7: 30%;
}

.form-container {
  position: absolute;
  z-index: 0;

  height: 90vh;
  width: 98vw;
}

.form,
.tn1 {
  max-width: 400px;
  max-height: 400px;
}

.tn2 {
  max-width: 500px;
  max-height: 500px;
}

.stroke {
  max-width: 900px;
  max-height: 900px;
}

@media screen and (max-width: 1200px) {
  .form,
  .tn1 {
    max-width: calc(400px - var(--coeff1));
    max-height: calc(400px - var(--coeff1));
  }
  .tn2 {
    max-width: calc(500px - var(--coeff1));
    max-height: calc(500px - var(--coeff1));
  }

  .stroke {
    max-width: calc(800px - var(--coeff1));
    max-height: calc(800px - var(--coeff1));
  }
}

@media screen and (max-width: 1024px) {
  .form,
  .tn1 {
    max-width: calc(400px - var(--coeff2));
    max-height: calc(400px - var(--coeff2));
  }
  .tn2 {
    max-width: calc(500px - var(--coeff2));
    max-height: calc(500px - var(--coeff2));
  }

  .stroke {
    max-width: calc(800px - 12%);
    max-height: calc(800px - 12%);
  }
}

@media screen and (max-height: 700px) {
  .form {
    max-width: calc(400px - var(--coeff3));
    max-height: calc(400px - var(--coeff3));
  }

  .stroke {
    max-width: calc(800px - var(--coeff3));
    max-height: calc(800px - var(--coeff3));
  }
}

@media screen and (max-width: 960px) {
  .form,
  .tn1 {
    max-width: calc(400px - var(--coeff3));
    max-height: calc(400px - var(--coeff3));
  }
  .tn2 {
    max-width: calc(500px - var(--coeff3));
    max-height: calc(500px - var(--coeff3));
  }

  .stroke {
    max-width: calc(800px - var(--coeff3));
    max-height: calc(800px - var(--coeff3));
  }
}

@media screen and (max-width: 960px) and (max-height: 700px) {
  .form {
    max-width: calc(400px - var(--coeff5));
    max-height: calc(400px - var(--coeff5));

    &--big {
      max-width: calc(500px - var(--coeff3));
      max-height: calc(500px - var(--coeff3));
    }
  }

  .stroke {
    max-width: calc(800px - var(--coeff3));
    max-height: calc(800px - var(--coeff3));
  }
}

@media screen and (max-width: 768px) {
  .form,
  .tn1 {
    max-width: calc(400px - var(--coeff5));
    max-height: calc(400px - var(--coeff5));
  }

  .stroke {
    max-width: calc(800px - var(--coeff6));
    max-height: calc(800px - var(--coeff6));
  }
}

@media screen and (max-width: 680px) {
  .stroke {
    display: none;
  }
}

@media screen and (max-width: 530px) {
}

@media screen and (max-width: 480px) {
  .tn2 {
    max-width: calc(500px - var(--coeff4));
    max-height: calc(500px - var(--coeff4));
  }
}

@media screen and (max-width: 320px) {
  .form,
  .tn1 {
    max-width: calc(400px - var(--coeff6));
    max-height: calc(400px - var(--coeff6));
  }
  .tn2 {
    max-width: calc(500px - var(--coeff6));
    max-height: calc(500px - var(--coeff6));
  }
}
