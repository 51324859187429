.certifications {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__item {
    text-align: right;
    border-right: 1px solid var(--mustard);
    padding-right: 10px;
    width: 65%;
    margin-bottom: 10px;

    &__title {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 20px;
      font-size: 0.9rem;
    }
  }

  &__item--important {
    text-align: right;
    border-radius: 10px;
    background: var(--mustard);
    color: var(--beige);
    padding: 10px;
    width: 65%;
    margin-bottom: 10px;
  }

  &__infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__basic {
      margin: 10px 0 5px;
    }

    &__text {
      margin: 5px 0;
      line-height: 20px;
    }

    &__description {
      margin: 5px 0;
      line-height: 20px;
      font-size: 0.9em;
      font-weight: 300;
      letter-spacing: 0.2px;
    }
  }
}

@media screen and (max-width: 960px) {
  .certifications {
    &__container {
      align-items: flex-start;
    }

    &__item {
      width: 90%;
      text-align: left;
      border-right: none;
      padding-right: 0;
      border-left: 1px solid var(--lightpink);
      padding-left: 10px;
    }
    &__item--important {
      text-align: left;
      width: 90%;
    }
  }
}
