.contact {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;

  &__intro {
    margin: 0 auto;
    width: 60%;
    line-height: 25px;
    &--bold {
      font-weight: 500;
    }
  }
}

.form {
  &__base {
    padding: 20px 0 0;
    margin: auto;
  }
  &__information {
    width: 80vw;
  }
  &__element {
    padding: 15px 0;
  }

  &__input {
    font-family: "Jost", sans-serif;
    padding: 5px 12px;
    margin-left: 12px;
    width: 300px;
    font-size: 18px;
    color: var(--darkred);

    border: none;
    border-radius: 20px;
  }
  &__warning {
    color: darkred;
    text-align: center;
    margin-top: 5px;
  }
  &__row-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__small-span {
    font-size: 0.8rem;
  }
}

::placeholder {
  color: var(--lightpink);
}

.--base {
  width: 480px;
}

.--text {
  width: 500px;
  height: 150px;
}

label input {
  margin-right: 5px;
}


@media screen and (max-width: 789px) {
  .form {
    &__element {
      padding: 10px 0;
    }

    &__row-block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__input {
      width: 300px;
      max-width: 90%;
      margin: 5px 0 0 0;
    }
    &__icon {
      display: none;
    }
  }

  .--text {
    width: 300px;
    height: 150px;
  }

  .--text {
    max-width: 90%;
  }

  .btn--option {
    margin: 10px;
  }

  .contact__intro {
    margin: 0 auto;
    width: 95%;
    line-height: 20px;
    &--bold {
      font-weight: 500;
    }
  }
}
