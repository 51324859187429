.footer {
  &__container {
    position: relative;
    z-index: 10;

    width: 100%;
    height: 50px;

    font-family: "Jost", sans-serif;

    background-color: var(--lightpink);
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--white);
  }

  &__content {
    display: flex;
    flex-direction: row;
    max-width: 90%;
  }

  &__title {
    text-align: right;
    white-space: nowrap;
    margin: auto 15px;
  }

  &__links {
    width: 100%;
    display: flex;
    justify-content: center;
    border-left: 1px solid var(--white);
  }

  &__link {
    &__wrapper {
      display: flex;
    }

    &__items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 5px 30px;
      text-align: left;
      box-sizing: border-box;
      min-width: 30%;
      & .h2 {
        margin-bottom: 16px;
      }

      & a {
        color: var(--white);
        text-decoration: none;
        margin-bottom: 0.5rem;
      }

      & a:hover {
        transition: 0.5s ease-out;
        text-decoration: underline;
      }
    }
  }
  &__website-rights {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin: auto 15px;
  }
}

/* Social Icons */
.social__icon {
  &-link {
    color: var(--white);
    font-size: 1.5rem;

    &:hover {
      transition: 0.2s ease-out;
      transform: scale(1.3);
    }
  }
}

.social__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin-left: 35px;
  padding-right: 35px;
  border-right: 1px solid var(--white);
}

/* Copyright */

@media screen and (max-width: 820px) {
  .footer {
    &__container {
      height: 110px;
    }

    &__content {
      flex-direction: column;
      max-width: 90%;
    }

    &__title {
      display: none;
    }

    &__links {
      border-left: none;
    }

    &__link__items {
      align-items: center;
    }

    &__link__wrapper {
      flex-direction: column;
    }

    &__website-rights {
      margin: 10px auto;
    }
  }

  .social__icons {
    margin: auto;
    border-right: none;
    padding-right: 0;
  }
}
