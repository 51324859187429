.about-desktop {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  font-size: 20px;

  &__intro {
    margin: 30px auto 0;
    font-size: 18px;
    width: 75%;
    line-height: 25px;

    &--bold {
      font-weight: 500;
    }

    &--pgp {
      margin-bottom: 10px;
    }
  }

  &__ensemble {
    position: block;
    height: 400px;
    width: 800px;
    border-top-left-radius: 410px;
    border-top-right-radius: 410px;
  }

  &__tag {
    position: relative;
    top: -100px;
    margin: -23px;
  }
}

.one {
  transform: rotate(-180deg) translate(325px);
  &__text {
    transform: rotate(-180deg);
  }
}

.two {
  transform: rotate(-150deg) translate(375px);
  &__text {
    transform: rotate(-210deg);
  }
}

.three {
  transform: rotate(-90deg) translate(325px);
  &__text {
    transform: rotate(-270deg);
  }
}

.four {
  transform: rotate(-30deg) translate(375px);
  &__text {
    transform: rotate(-330deg);
  }
}

.five {
  transform: rotate(0deg) translate(325px);
}

@media screen and (max-width: 950px) {
  .one,
  .two,
  .three,
  .four,
  .five {
    display: none;
  }
}

@media screen and (max-width: 789px) {
  .about-desktop{
    padding-bottom: 50px;

    &__ensemble {
      position: block;
      height: 200px;
      width: 400px;
      border-top-left-radius: 210px;
      border-top-right-radius: 210px;
    }

    &__intro {
      margin: 30px auto 0;
      font-size: 16px;
      width: 90%;
      line-height: 23px;
  
  
      &--pgp {
        margin-bottom: 10px;
      }
    }
  }
}
