.form-container--nav {
  position: fixed;
  z-index: 10;

  height: 80px;
  width: 100%;
}

.tn1 {
  position: absolute;
  z-index: 1;
  left: -200px;
  top: -300px;
}

.tn2 {
  position: absolute;
  z-index: 1;
  right: -40px;
  top: -200px;
}

@media screen and (max-width: 1200px) {
  .tn1 {
    left: -175px;
    top: -250px;
  }

  .tn2 {
    top: -170px;
  }
}

@media screen and (max-width: 960px) {
  .tn1 {
    left: -150px;
    top: -225px;
  }

  .tn2 {
    right: -175px;
    top: -170px;
  }
}

@media screen and (max-width: 820px) {
  .tn1 {
    top: -260px;
  }
}

@media screen and (max-width: 768px) {
  .tn1 {
    left: -120px;
    top: -190px;
  }
}

@media screen and (max-width: 600px) {
  .tn1 {
    left: -140px;
    top: -240px;
  }

  .tn2 {
    right: -230px;
    top: -180px;
  }
}


@media screen and (max-width: 420px) {

  .tn1 {
    left: -170px;
    top: -270px;
  }

}


@media screen and (max-width: 400px) {

  .tn1 {
    left: -190px;
    top: -280px;
  }


  .tn2 {
    right: -250px;
    top: -190px;
  }
}

@media screen and (max-width: 320px) {
  .tn1 {
    left: -190px;
    top: -280px;
  }


  .tn2 {
    right: -250px;
    top: -170px;
  }
}
