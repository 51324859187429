.card4 {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;

  width: 700px;
  height: 25ch;
  background-image: url(https://www.vhv.rs/dpng/f/457-4577783_moon-icon-png.png);
}

.bh1 {
  position: absolute;
  left: -40px;
  bottom: 100px;
}

.bh2 {
  position: absolute;
  left: -50px;
  bottom: -120px;
  transition: all 0.8s ease-out;
  -webkit-transition: all 0.8s ease-out;
}

.bh3 {
  position: absolute;
  left: 200px;
  bottom: -150px;
  width: 300px;
  transition: all 0.8s ease-out;
  -webkit-transition: all 0.8s ease-out;
}

.bh4 {
  position: absolute;
  right: 120px;
  bottom: -150px;
  width: 350px;
  transition: all 0.8s ease-out;
  -webkit-transition: all 0.8s ease-out;
}

.bh5 {
  position: absolute;
  right: -150px;
  bottom: -100px;
  transition: all 0.8s ease-out;
  -webkit-transition: all 0.8s ease-out;
}

.ch1 {
  position: absolute;
  right: -80px;
  bottom: -50px;
}

.ch2 {
  position: absolute;
  left: -10px;
  bottom: -30px;
}

.fp-viewing-Work .bh2 {
  bottom: 150px;
  transition: all 1.1s ease-in-out;
  -webkit-transition: all 1.1s ease-in-out;
}

.fp-viewing-Work .bh3 {
  bottom: 150px;
  transition: all 1.1s ease-in-out;
  -webkit-transition: all 1.1s ease-in-out;
}

.fp-viewing-Work .bh4 {
  bottom: 150px;
  transition: all 1.1s ease-in-out;
  -webkit-transition: all 1.1s ease-in-out;
}
.fp-viewing-Work .bh5 {
  bottom: 150px;
  transition: all 1.1s ease-in-out;
  -webkit-transition: all 1.1s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .ch1 {
    bottom: calc(-50px + var(--coeff4));
  }

  .ch2 {
    bottom: calc(-30px + var(--coeff3));
  }

}

@media screen and (max-height: 700px){
  .ch1 {
    bottom: calc(-50px - var(--coeff4));
  }

  .ch2 {
    bottom: calc(-30px - var(--coeff3));
  }

  .bh1 {
    bottom: calc(100px - var(--coeff2));
  }

  .bh3 {
    left: calc(200px - var(--coeff1));
  }

  .bh5 {
    right: -120px;
    bottom: calc(-100px - var(--coeff2));
  }

  
}

@media screen and (max-width: 960px) {
  .bh1 {
    left: -40px;
    bottom: calc(100px + var(--coeff2));
  }

  .bh2 {
    left: -50px;
    bottom: calc(-80px + var(--coeff2));
  }

  .bh3 {
    left: calc(200px - var(--coeff3));
    bottom: calc(-150px + var(--coeff3));
    width: calc(300px - var(--coeff1));
  }

  .bh4 {
    right: calc(120px - var(--coeff4));
    bottom: calc(-150px + var(--coeff3));
    width: calc(350px - var(--coeff1));
  }

  .bh5 {
    right: -150px;
    bottom: calc(-100px + var(--coeff2));
  }

  .ch1 {
    right: calc(-80px - var(--coeff1));
    bottom: calc(-50px + var(--coeff5));
  }

  .ch2 {
    right: calc(-10px - var(--coeff1));
    bottom: calc(-30px + var(--coeff4));
  }
}


@media screen and (max-width: 768px) {
  .bh1 {
    bottom: 100px;
  }

  .bh2 {
    bottom: calc(-80px + var(--coeff1));
  }

  .bh3 {
    left: calc(200px - var(--coeff4));
    bottom: calc(-150px + var(--coeff2));
    width: calc(300px - var(--coeff2));
  }

  .bh4 {
    right: calc(120px - var(--coeff2));
    bottom: calc(-150px + var(--coeff2));
    width: calc(350px - var(--coeff4));
  }

  .bh5 {
    right: calc(-150px + var(--coeff3));
    bottom: calc(-100px + var(--coeff1));
  }

  .ch1 {
    right: calc(-80px - var(--coeff1));
    bottom: calc(-50px + var(--coeff5));
  }

  .ch2 {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .bh4 {
    visibility: hidden;
  }

  .stroke {
    display: none;
  }
}


@media screen and (max-width: 480px) {
  .bh2,
  .bh3,
  .ch1 {
    display: none;
  }

  .bh1 {
    height: calc(300px - var(--coeff3));
  }

  .bh4 {
    visibility: visible;
    left: -150px;
    bottom: -80px;
    width: calc(300px - var(--coeff2));
  }

  .bh5 {
    visibility: visible;
    right: -150px;
    bottom: -80px;
    width: calc(300px - var(--coeff2));
  }
}

// @media screen and (max-width: 960px) and (max-height: 700px) {
//   .bh2,
//   .bh3,
//   .ch1, 
//   .bh1 {
//     display: none;
//   }

//   .bh4 {
//     bottom: -100px;
//     width: calc(300px - var(--coeff3));
//   }


//   .bh5 {
//     visibility: visible;
//     right: -150px;
//     bottom: -80px;
//     width: calc(300px - var(--coeff5));
//   }
// }


@media screen and (max-width: 320px) {

}
