@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.arrows-slide {
  font-size: 30px;
  margin: 0 20px;
  color: var(--darkred);
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 8px;
  margin-top: 20px;
  color: var(--darkred);
}

.slick-dots li.slick-active button:before {
  color: var(--darkred);
}

/* IMAGE SLIDER */

.slide {
  transform: scale(0.6);
  transition: transform 300ms;
  opacity: 0.5;
  max-width: 95%;
  margin-top: 80px;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

// .arrow svg:hover {
//   color: black;
// }

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

@media screen and (max-width: 1395px) {
  .arrows-slide {
    font-size: 25px;
    margin: 0 -40px;
  }
}

@media screen and (max-width: 1020px) {
  .arrows-slide {
    font-size: 20px;
    margin: 0 -30px;
  }
}

@media screen and (max-width: 768px) {
  .slick-dots li button:before {
    font-size: 10px;
    line-height: 0;
  }

  .slide {
    margin-top: 20px;
  }

}

// COMPONENT RELATED STYLING

.project {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: left;
  }

  &__description {
    width: 30%;
    &__title {
      margin: 20px 0;
      font-family: "Jost", sans-serif;
      font-weight: 500;
    }

    &__undertitle {
      margin: 20px 0;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
    }

    &__text {
      line-height: 1.5rem;
    }
  }

  &__technology {
    &__tag {
      padding: 6px 12px;
      margin: 0 5px 5px 0;
      background-color: var(--lightpink);
      border-radius: 25px;
      display: inline-block;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;

    &__icon {
      color: var(--darkred);
      font-size: 1.5rem;
      margin: 0 15px 0 0;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    width: 50%;
    max-height: 550px;

    &__item {
      width: 95%;
    }
  }
}

@media screen and (max-width: 769px) {
  .project {
    &__container {
      display: flex;
      flex-direction: column;
    }

    &__description {
      width: 100%;

      &__title,
      &__text {
        margin: 1.5rem;
      }
      &__text {
        line-height: 1.4rem;
      }
    }

    &__category{
      margin: 1.5rem;
    }

    &__image {
      justify-content: center;
      width: 100%;
      &__item {
        width: 80%;
      }
    }
  }
}
